import NextLink from 'next/link';
import MUILink from '@material-ui/core/Link';

/**
 * @typedef {import('@material-ui/core/Link').LinkProps} MUILinkProps
 * @typedef {import('next/link').LinkProps} NextLinkProps
 * @param {object} props
 * @param {string} props.href
 * @param {boolean} props.nextLink
 * @param {MUILinkProps} props.MUILinkProps
 * @param {NextLinkProps} props.NextLinkProps
 * @returns {JSX.Element}
 */
const Link = ({
    href, nextLink = true, children, MUILinkProps, NextLinkProps,
}) => (nextLink ? (
    <NextLink href={href} {...NextLinkProps}>
        <MUILink href={href} {...MUILinkProps}>
            {children}
        </MUILink>
    </NextLink>
) : (
    <MUILink href={href} {...MUILinkProps} />
));

export default Link;
