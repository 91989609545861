import Box from '@material-ui/core/Box';
import MUIButton from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import withStyles from '@material-ui/core/styles/withStyles';
import classNames from 'classnames';

const styles = {
    root: {
        position: 'relative',
        '& .MuiButtonBase-root': {
            borderRadius: 4,
            paddingTop: 14,
            paddingBottom: 14,
            fontSize: 16,
            fontWeight: 600,
            lineHeight: 1,
            width: '100%',
            boxShadow: 'none',
            '&.MuiButton-outlinedPrimary': {
                borderColor: '#4479BA',
            },
        },
        '& .MuiCircularProgress-root': {
            position: 'absolute',
            top: '50%',
            left: '50%',
            marginTop: -12,
            marginLeft: -12,
        },
    },
};

/**
 * @typedef {import('@material-ui/core/Box').BoxProps} BoxProps
 * @typedef {import('@material-ui/core/Button').ButtonProps} ButtonProps
 * @typedef {import('@material-ui/core/CircularProgress').CircularProgressProps} CircularProgressProps
 * @param {object} props
 * @param {boolean} props.loading - If true, show loading indicator
 * @param {boolean} props.disabled
 * @param {React.MouseEvent<import('@material-ui/core/Button').default>} props.onClick
 * @param {BoxProps} props.BoxProps
 * @param {ButtonProps} props.ButtonProps
 * @param {CircularProgressProps} props.CircularProgressProps
 * @returns {JSX.Element}
 */
const Button = ({
    children, loading, disabled, onClick, BoxProps, ButtonProps, CircularProgressProps, classes, className, type,
}) => (
    <Box className={classNames(classes.root, className)} {...BoxProps}>
        <MUIButton variant="contained" color="primary" onClick={onClick} disabled={disabled || loading} type={type} {...ButtonProps}>
            {children}
        </MUIButton>
        {loading && <CircularProgress size={24} {...CircularProgressProps} />}
    </Box>
);

export default withStyles(styles)(Button);
